
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.client-selector::v-deep {
    .v-input__control {
        .v-input__slot {
            font-weight: 500;
        }
    }
}
