.a-list-row {
    border-radius: 5px;

    &.announcement-archived {
        opacity: 0.5;
    }

    .cell-disabled {
        opacity: 0.5;
    }

    &.with-action {
        cursor: pointer;
    }

    .new-comments--circle {
        position: absolute;
        top: 0.25em;
        right: 0.25em;
        background-color: $error;
        border-radius: 100%;
        width: 0.75em;
        height: 0.75em;

        &:before {
            content: '';
            width: 0.35em;
            height: 0.35em;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            border-radius: 100%;
        }

        &.new-comments--circle-outer {
            top: -4px;
            right: -5px;
        }

        &.new-comments--circle-list {
            right: auto;
            left: 0.25em;
        }
    }
}
