
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.filter-button.active {
    background-color: $faded-blue;
}

.filter-option::v-deep {
    .v-label {
        color: $secondary-color;
    }
}
