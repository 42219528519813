
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.welcome-banner::v-deep {
    &.ampcast {
        background-image: none;

        &:before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url('@/assets/img/ampcast-logo-black.svg');
            background-size: 11vw;
            background-position: calc(100% - 20px) 20px;
            opacity: 0.8;
            z-index: 1;
        }
    }

    div + div {
        &::before {
            content: '';
            display: block;
            position: relative;
            top: -8px;
            height: 0;
            width: 50%;
            border-top: 1px solid $secondary-color;
            opacity: 0.3;
        }
    }

    div:first-of-type {
        margin-top: 0 !important;
    }

    div:last-of-type {
        margin-bottom: 0 !important;
    }
}
