
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.slider::v-deep {
    .v-carousel__controls {
        .v-item-group {
            .v-carousel__controls__item {
                margin: 0 6px 0 0;

                &:before {
                    background-color: transparent !important;
                }

                .v-btn__content svg {
                    opacity: 1 !important;
                }

                .v-ripple__container {
                    visibility: hidden;
                }

                &:not(.v-item--active) {
                    .v-btn__content svg {
                        opacity: 0.2 !important;
                    }
                }
            }
        }
    }
}
