
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-pagination::v-deep {
    .v-pagination.theme--light {
        .v-pagination__item {
            font-size: 100%;
        }

        .v-pagination__item:not(.v-pagination__item--active) {
            &:hover {
                background-color: $primary-color;
                color: $white;
            }
        }

        .v-pagination__navigation {
            box-shadow: none;
            &:hover {
                background-color: $primary-color;
                .v-icon {
                    color: $white;
                }
            }
        }
    }

    .v-input {
        font-size: 100%;
    }

    &.main-background .v-pagination.theme--light {
        .v-pagination__navigation {
            background: transparent;
            &:hover {
                background-color: $primary-color;
                .v-icon {
                    color: $white;
                }
            }
        }
    }

    .select--wrap-width {
        max-width: min-content !important;
        padding: 0;
        margin: 0;
    }
}
