
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.training-card::v-deep {
    opacity: 0.8;
    transition: all 0.1s ease-in-out;

    .v-image {
        background-color: black !important;
        box-shadow: none;
        cursor: pointer;
    }

    &:hover {
        .v-image {
            box-shadow: 0px 1px 2px 0px #0000001a !important;
        }
        opacity: 1;
    }

    .v-icon {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
            path {
                fill: white !important;
                stroke: white !important;
            }
        }
    }
}
