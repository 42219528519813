
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.modal-wrapper {
    .close-btn {
        color: #dfdbdb;
    }

    .text-content {
        font-size: 1.125em;
        font-weight: 400;
        max-width: 50ch;
        margin-inline: auto;
    }
    .create-btn {
        width: 272px;
    }

    .btn-height {
        height: 45px;
    }
}

.v-card__text {
    padding-bottom: 0;
}

.v-card__actions {
    padding-top: 0 !important;
    gap: 1.25rem;
    flex-wrap: wrap;

    .v-btn {
        margin-left: 0 !important;
    }

    .container {
        padding: 0 !important;
        width: auto;
        margin-inline: 0;
    }
}
