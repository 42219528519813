.amp-card {
    &.amp-card--top-border {
        border-top-style: solid;
        border-top-width: 0.25em;

        &.draft,
        &.pending,
        &.in_progress,
        &.awaiting_preview_audio,
        &.assigned,
        &.paused {
            border-top-color: $dark-orange;
        }

        &.rejected {
            border-top-color: $boulder;
        }

        &.live,
        &.published,
        &.resolved {
            border-top-color: $turquoise;
        }

        &.in_queue,
        &.awaiting_distribution,
        &.unapproved,
        &.in_review,
        &.awaiting_client,
        &.pending_distribution,
        &.partially_published {
            border-top-color: $faded-blue;
        }
    }

    .status-bar > :not(:first-child) {
        margin-left: 5px;
    }

    .headline {
        white-space: initial;
    }

    .amp-card--date {
        color: $tertiary-color !important;
    }
}
